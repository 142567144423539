import React, { useLayoutEffect, useRef } from 'react';
import { calcScrollPercent } from '~/lib/utils';
import './Sliders.scss';

const Sliders = ({ className }) => {
  const svgRef = useRef(null);

  const calcDraw = () => {
    const movementAllowance = 1;
    // if (screen.width < 768) movementAllowance = 0.6;

    const svgEl = svgRef.current;
    const { height } = svgEl.getBoundingClientRect();
    const scrollPercent = calcScrollPercent({
      element: svgEl,
      scrollDurationCoefficient: 0.5
    });
    const sliders = svgEl.querySelectorAll('g');
    const slideDistances = [0.75, 0.5, -1.25];
    sliders.forEach(
      (slider, i) =>
        (slider.style.transform = `translate3d(0, ${scrollPercent *
          height *
          movementAllowance *
          slideDistances[i]}px, 0)`)
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', calcDraw);
    return () => window.removeEventListener('scroll', calcDraw);
  }, []);

  return (
    <svg
      className={['sliders-svg', className].join(' ')}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 905.4 1439.7"
      ref={svgRef}
    >
      <path
        className="st0"
        d="M77.7,1432.2L77.7,1432.2c-18.1,0-33-14.8-33-33V40.5c0-18.1,14.8-33,33-33l0,0c18.1,0,33,14.8,33,33v1358.7
                C110.7,1417.4,95.8,1432.2,77.7,1432.2z"
      />
      <path
        className="st1"
        d="M77.7,1439.7c-22.3,0-40.5-18.2-40.5-40.5V40.5C37.2,18.2,55.4,0,77.7,0s40.5,18.2,40.5,40.5v1358.7
                C118.2,1421.6,100,1439.7,77.7,1439.7z M77.7,15c-14.1,0-25.5,11.4-25.5,25.5v1358.7c0,14.1,11.4,25.5,25.5,25.5
                s25.5-11.4,25.5-25.5V40.5C103.2,26.5,91.7,15,77.7,15z"
      />
      <g>
        <path
          className="st2"
          d="M77.7,761.3L77.7,761.3c-38.6,0-70.2-31.6-70.2-70.2V432.9c0-38.6,31.6-70.2,70.2-70.2l0,0
                        c38.6,0,70.2,31.6,70.2,70.2v258.2C147.9,729.7,116.3,761.3,77.7,761.3z"
        />
        <path
          className="st1"
          d="M77.7,768.8C34.8,768.8,0,733.9,0,691.1V432.9c0-42.9,34.9-77.7,77.7-77.7s77.7,34.9,77.7,77.7v258.2
                        C155.4,734,120.5,768.8,77.7,768.8z M77.7,370.2c-34.6,0-62.7,28.1-62.7,62.7v258.2c0,34.6,28.1,62.7,62.7,62.7s62.7-28.1,62.7-62.7
                        V432.9C140.4,398.4,112.3,370.2,77.7,370.2z"
        />
        <path
          className="st2"
          d="M93.5,562c0,8.7-7.1,15.8-15.8,15.8c0,0,0,0,0,0c-8.7,0-15.8-7.1-15.8-15.8l0,0c0-8.7,7.1-15.8,15.8-15.8
                        c0,0,0,0,0,0C86.4,546.2,93.5,553.3,93.5,562L93.5,562z"
        />
        <path
          className="st1"
          d="M77.7,585.3c-12.8,0-23.3-10.4-23.3-23.3s10.4-23.3,23.3-23.3S101,549.1,101,562S90.5,585.3,77.7,585.3z
                        M77.7,553.8c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S82.2,553.8,77.7,553.8z"
        />
      </g>
      <path
        className="st0"
        d="M453.1,1432.2L453.1,1432.2c-18.1,0-33-14.8-33-33V40.5c0-18.1,14.8-33,33-33l0,0c18.1,0,33,14.8,33,33v1358.7
                C486.1,1417.4,471.2,1432.2,453.1,1432.2z"
      />
      <path
        className="st1"
        d="M453.1,1439.7c-22.3,0-40.5-18.2-40.5-40.5V40.5c0-22.3,18.2-40.5,40.5-40.5c22.3,0,40.5,18.2,40.5,40.5v1358.7
                C493.6,1421.6,475.4,1439.7,453.1,1439.7z M453.1,15c-14.1,0-25.5,11.4-25.5,25.5v1358.7c0,14.1,11.4,25.5,25.5,25.5
                s25.5-11.4,25.5-25.5V40.5C478.6,26.5,467.1,15,453.1,15z"
      />
      <g>
        <path
          className="st2"
          d="M453.1,351.2L453.1,351.2c-38.6,0-70.2-31.6-70.2-70.2l0,0c0-38.6,31.6-70.2,70.2-70.2l0,0
                c38.6,0,70.2,31.6,70.2,70.2l0,0C523.3,319.6,491.7,351.2,453.1,351.2z"
        />
        <path
          className="st1"
          d="M453.1,358.7c-42.9,0-77.7-34.9-77.7-77.7s34.9-77.7,77.7-77.7c42.8,0,77.7,34.9,77.7,77.7
                S495.9,358.7,453.1,358.7z M453.1,218.2c-34.6,0-62.7,28.1-62.7,62.7s28.1,62.7,62.7,62.7c34.6,0,62.7-28.1,62.7-62.7
                S487.7,218.2,453.1,218.2z"
        />
        <path
          className="st2"
          d="M468.9,280.9c0,8.7-7.1,15.8-15.8,15.8c0,0,0,0,0,0c-8.7,0-15.8-7.1-15.8-15.8s7.1-15.8,15.8-15.8
                S468.9,272.2,468.9,280.9C468.9,280.9,468.9,280.9,468.9,280.9z"
        />
        <path
          className="st1"
          d="M453.1,304.2c-12.8,0-23.3-10.4-23.3-23.3s10.4-23.3,23.3-23.3c12.9,0,23.3,10.4,23.3,23.3
                S465.9,304.2,453.1,304.2z M453.1,272.7c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S457.6,272.7,453.1,272.7z"
        />
      </g>
      <path
        className="st0"
        d="M827.7,1432.2L827.7,1432.2c-18.1,0-33-14.8-33-33V40.5c0-18.1,14.8-33,33-33l0,0c18.1,0,33,14.8,33,33v1358.7
                C860.7,1417.4,845.8,1432.2,827.7,1432.2z"
      />
      <path
        className="st1"
        d="M827.7,1439.7c-22.3,0-40.5-18.2-40.5-40.5V40.5c0-22.3,18.2-40.5,40.5-40.5s40.5,18.2,40.5,40.5v1358.7
                C868.2,1421.6,850,1439.7,827.7,1439.7z M827.7,15c-14.1,0-25.5,11.4-25.5,25.5v1358.7c0,14.1,11.4,25.5,25.5,25.5
                s25.5-11.4,25.5-25.5V40.5C853.2,26.5,841.7,15,827.7,15z"
      />
      <g>
        <path
          className="st2"
          d="M827.7,1161.3L827.7,1161.3c-38.6,0-70.2-31.6-70.2-70.2V832.9c0-38.6,31.6-70.2,70.2-70.2l0,0
                c38.6,0,70.2,31.6,70.2,70.2v258.2C897.9,1129.7,866.3,1161.3,827.7,1161.3z"
        />
        <path
          className="st1"
          d="M827.7,1168.8c-42.9,0-77.7-34.9-77.7-77.7V832.9c0-42.9,34.9-77.7,77.7-77.7s77.7,34.9,77.7,77.7v258.2
                C905.4,1134,870.5,1168.8,827.7,1168.8z M827.7,770.2c-34.6,0-62.7,28.1-62.7,62.7v258.2c0,34.6,28.1,62.7,62.7,62.7
                s62.7-28.1,62.7-62.7V832.9C890.4,798.4,862.3,770.2,827.7,770.2z"
        />
        <path
          className="st2"
          d="M843.5,962c0,8.7-7.1,15.8-15.8,15.8c0,0,0,0,0,0c-8.7,0-15.8-7.1-15.8-15.8l0,0c0-8.7,7.1-15.8,15.8-15.8
                c0,0,0,0,0,0C836.4,946.2,843.5,953.3,843.5,962L843.5,962z"
        />
        <path
          className="st1"
          d="M827.7,985.3c-12.8,0-23.3-10.4-23.3-23.3s10.4-23.3,23.3-23.3S851,949.1,851,962S840.5,985.3,827.7,985.3z
                M827.7,953.8c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S832.2,953.8,827.7,953.8z"
        />
      </g>
    </svg>
  );
};

export default Sliders;
