import React, { useLayoutEffect, useRef } from 'react';
import { calcScrollPercent } from '~/lib/utils';
import './Headphones.scss';

const Headphones = ({ className }) => {
  const svgRef = useRef(null);
  const soundWaveRef = useRef(null);

  const animate = () => {
    let scrollDurationCoefficient = 0.2;
    if (screen.width <= 1024) scrollDurationCoefficient = 0.12;

    const svgEl = svgRef.current;
    const soundWaveEl = soundWaveRef.current;
    const length = soundWaveEl.getTotalLength();
    const scrollPercent = calcScrollPercent({
      element: svgEl,
      scrollDurationCoefficient
    });

    soundWaveEl.style.strokeDasharray = length;
    soundWaveEl.style.strokeDashoffset = length - length * scrollPercent;
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', animate);
    return () => window.removeEventListener('scroll', animate);
  }, []);

  return (
    <svg
      className={['headphones-svg', className].join(' ')}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 953.6 789"
      xmlSpace="preserve"
      ref={svgRef}
    >
      <path
        ref={soundWaveRef}
        className="st0"
        d="M539,764.3c0,0-0.1,0-0.1,0c-1.8-0.1-3.2-2.8-3.5-6.4l-20.2-307l-20.2,307c-0.2,3.6-1.8,6.3-3.6,6.4
                c0,0,0,0,0,0c-1.8,0-3.3-2.6-3.6-6.2l-20.7-269.8l-19.7,192.2c-0.4,3.4-1.8,5.8-3.6,5.8c-1.7,0-3.2-2.4-3.6-5.8L420,483.2
                l-20.2,197.2c-0.3,3.2-1.7,5.6-3.3,5.8c-1.6,0.3-3.1-1.7-3.7-4.7L373,575l-20.9,157.8c-0.4,3.3-2,5.5-3.6,5.4
                c-1.7-0.1-3.1-2.5-3.5-5.8l-20.7-201.6l-19.8,150c-0.4,3-1.7,5.1-3.2,5.4c-1.5,0.3-3-1.4-3.6-4.2l-21-89.9l-20.3,64.7
                c-0.6,1.9-1.6,3.2-2.7,3.4c-1.1,0.2-2.2-0.6-3-2.3l-23.8-51c-1.4-3-1.3-7.6,0.2-10.4c1.5-2.8,3.8-2.6,5.1,0.3l20.6,44.1l21.3-68
                c0.7-2.3,2-3.6,3.3-3.4c1.3,0.2,2.5,1.8,3.1,4.2l19.6,83.8l21.2-160.6c0.4-3.3,1.9-5.5,3.6-5.4c1.7,0.1,3.1,2.5,3.5,5.8L349,699.1
                l19.8-150c0.4-3.1,1.8-5.3,3.3-5.4c1.6-0.2,3,1.8,3.6,4.8l19.4,104.2l21.3-207.1c0.3-3.4,1.8-5.9,3.6-5.9s3.2,2.4,3.6,5.9
                l20.2,197.2L464,445.5c0.4-3.5,1.9-6,3.7-5.8c1.8,0.1,3.2,2.7,3.5,6.2l19.9,259.4l20.5-311.3c0.2-3.6,1.8-6.4,3.6-6.4
                c1.8,0,3.4,2.7,3.6,6.4l21,319l19.5-189.5c0.3-3.4,1.8-5.8,3.6-5.8c1.7,0,3.2,2.4,3.6,5.8l20.2,197.2l20.2-197.2
                c0.3-3.3,1.7-5.7,3.4-5.8c1.7-0.2,3.2,2,3.7,5.1l20.1,129.7l20.6-155.4c0.4-3.1,1.8-5.3,3.4-5.4c1.6-0.1,3,1.8,3.6,4.8L683,617.3
                l19.7-50.1c1.2-2.9,3.2-3.6,4.8-1.6l23.8,30.2c1.7,2.2,2.2,6.7,1.1,10.1c-1.1,3.4-3.3,4.5-5,2.3l-21-26.7l-21.8,55.3
                c-0.8,2.1-2.1,3.1-3.4,2.7c-1.3-0.4-2.4-2.2-2.8-4.8l-19.9-111.1l-20.8,157.3c-0.4,3.2-1.8,5.4-3.4,5.4c-1.6,0-3.1-2-3.6-5.2
                l-19.6-126.9l-21,204.2c-0.4,3.4-1.8,5.8-3.6,5.8c-1.7,0-3.2-2.4-3.6-5.8l-20.2-197.2l-20.2,197.2
                C542.2,761.8,540.7,764.3,539,764.3L539,764.3z"
      />
      <g id="svg_2">
        <g id="svg_3">
          <path
            id="XMLID_5_"
            className="st1"
            d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_5"
            className="st2"
            d="M83.4,449.2c-4.3-0.5-60.3,21.5-60.3,21.5L-21.6,579l14,83.1l57.1,95.6l73.8,12.4L83.4,449.2z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_3_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_6"
            className="st3"
            d="M877.2,449.2c4.3-0.5,60.3,21.5,60.3,21.5L982.1,579l-14,83.1l-57.1,95.6l-73.8,12.4L877.2,449.2
                                L877.2,449.2z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_5_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polygon
            id="svg_7"
            className="st4"
            points="42.6,454.1 100.6,777.1 96.2,407.2             "
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_7_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlinkHref="#SVGID_7_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polygon
            id="svg_8"
            className="st5"
            points="910.7,454.1 852.7,777.1 857.2,407.2           "
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_9_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlinkHref="#SVGID_9_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_9"
            className="st6"
            d="M124.6,314.4c0,0,16.1,9.1,29.4,7.6c2.6-0.3,35.9-5.3,35.9-5.3l118.5-151.2l210.5-49.1
                                l217.2,162.9l37.2,57.3l31.1-14.4c0,0,13.1-1.5,24.5-7.4c3.3-1.7,20.7-17.4,20.7-17.4l10.6-60l-59.3-97.1L630.4-1.2L368.6-11.6
                                L141.7,150.6l-50.6,97.1l9.7,53.9L124.6,314.4L124.6,314.4z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_11_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlinkHref="#SVGID_11_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_10"
            className="st7"
            d="M115.7,420l25.2-2.5l63.9-8.9l45.9,37.4c0,0,28,324.4,31,323.9c3-0.6-170.8,42.4-172.2,39.6
                                c-1.4-2.8-13.1-38.2-13.1-38.2l2-12.3L67.8,454.6L65,431l13-8.4L115.7,420L115.7,420z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_13_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_14_">
            <use xlinkHref="#SVGID_13_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_11"
            className="st8"
            d="M838.3,420l-25.2-2.5l-63.9-8.9L703.4,446c0,0-28,324.4-31,323.9c-3-0.6,170.8,42.4,172.2,39.6
                                c1.4-2.8,13.1-38.2,13.1-38.2l-2-12.3l30.6-304.3L889,431l-13-8.4L838.3,420L838.3,420z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_15_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_16_">
            <use xlinkHref="#SVGID_15_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            id="svg_12"
            className="st9"
            d="M223.5,74.7c0,0,315.4-145.8,589.9,90c21.6,18.6-183-155.5-183-155.5l-268-16.5L223.5,74.7z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_17_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlinkHref="#SVGID_17_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polygon
            className="st10 left-pulse"
            points="186,401.6 229.5,794.4 274.1,785.6 229.5,388.2            "
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_19_"
              d="M886.2,454.6l0-0.6c0.8-7.7-1.5-15.3-6.4-21.3c-4.9-6-11.9-9.8-19.6-10.5l-21.9-2.2
                                        c0.4-6.8,0.6-13.6,0.6-20.3c0-28.8-3.4-57.3-10-84.9c17.4-11.4,25.2-33.7,17.6-53.9C789,107.8,640.5,5,477,5
                                        C313.5,5,165,107.8,107.5,260.8c-7.6,20.2,0.3,42.5,17.6,53.9c-6.7,27.7-10,56.2-10,84.9c0,6.8,0.2,13.6,0.6,20.3l-21.9,2.2
                                        c-7.7,0.8-14.7,4.5-19.6,10.5c-4.9,6-7.2,13.6-6.4,21.3l0.1,0.6C24.1,470-3.1,536.5,4.8,614.6c7.9,78.1,47.8,138,93.7,144.3
                                        l0.1,0.5c1.5,15,14.2,26.2,28.9,26.2c1,0,2-0.1,2.9-0.1L231,775.3h0c16-1.6,27.6-15.9,26-31.9l-30.7-305.3
                                        c-1.6-16-15.9-27.6-31.9-26l-53.5,5.4c-0.3-5.9-0.5-11.9-0.5-17.8c0-26.2,3.2-52.2,9.2-77.5c18.5,0.1,36-11.1,42.9-29.4
                                        C236.8,175,351.1,95.8,477,95.8c125.9,0,240.2,79.1,284.5,196.9c6.6,17.6,23.7,29.4,42.5,29.4c0.1,0,0.3,0,0.4,0
                                        c6,25.3,9.2,51.3,9.2,77.5c0,5.9-0.2,11.9-0.5,17.8l-53.5-5.4c-7.8-0.8-15.3,1.5-21.3,6.4c-6,4.9-9.8,11.9-10.5,19.6L697,743.4
                                        c-0.8,7.7,1.5,15.3,6.4,21.3c4.9,6,11.9,9.8,19.6,10.5h0l100.6,10.1c1,0.1,2,0.1,2.9,0.1c14.7,0,27.4-11.2,28.9-26.2l0-0.5
                                        c45.9-6.3,85.8-66.2,93.7-144.3C957.1,536.5,929.9,470,886.2,454.6L886.2,454.6z"
            />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlinkHref="#SVGID_19_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polygon
            className="st11 right-pulse"
            points="768.8,401.6 725.3,794.4 680.6,785.6 725.3,388.2                 "
          />
        </g>
      </g>
    </svg>
  );
};

export default Headphones;
